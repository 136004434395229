@use 'styles' as theme;
.desktopSteps {
  @include theme.hideUntilDesktop;

  flex-direction: row;
}

.mobileSteps {
  @include theme.hideFromDesktop;
}

.card {
  flex: 1;
  align-items: center;
  text-align: center;
}

.illustration {
  height: theme.size(30);
}
