@use 'styles' as theme;
.root {
  padding-top: theme.size(3);
  padding-bottom: theme.size(3);
}

.white {
  background-color: theme.$colorWhite;
}

.grey {
  background-color: theme.$colorGrey50;
}

.image {
  margin-right: theme.size(1);
  margin-bottom: theme.size(1);

  @include theme.mediaDesktopUp {
    margin-bottom: 0;
  }
}

.wrapper {
  min-height: theme.size(6);
  display: flex;
  position: relative;
}

.trustWrapper {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;

  @include theme.mediaDesktopUp {
    flex-direction: row;
  }

  .trustBanner {
    @include theme.flexCenter($direction: column);
    flex: 1;

    @include theme.mediaDesktopUp {
      text-align: left;
      justify-content: flex-start;
      flex-direction: row;
    }
  }

  .trustText {
    max-width: theme.size(24);

    @include theme.mediaDesktopUp {
      margin-right: theme.size(4);
    }
  }

  .logo {
    opacity: 1;
  }

  .logoList {
    @include theme.mediaDesktopUp {
      margin-top: 0;
    }
  }
}
