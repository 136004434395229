@use 'styles' as theme;
$size: theme.size(6);

.root {
  display: none;

  @include theme.mediaDesktopUp {
    @include theme.flexCenter;
    @include theme.square($size);

    background-color: theme.$colorBlack;
    color: theme.$colorWhite;
    padding: 0;
    border-radius: $size;
    position: absolute;
    bottom: 0;
    right: 0;
    animation: circle 5s linear infinite;
  }
}

.icon {
  position: absolute;
  z-index: 1;
  animation: arrow 5s linear infinite;
}

@keyframes circle {
  0% {
    height: $size;
  }
  5% {
    height: calc($size + theme.size(0.5));
  }
  10% {
    height: calc($size + theme.size(1));
  }
  15% {
    height: calc($size + theme.size(0.5));
  }
  20% {
    height: $size;
  }
  100% {
    height: $size;
  }
}

@keyframes arrow {
  0% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-80%);
  }
  20% {
    transform: translateY(60%);
  }
  25% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
