@use 'styles' as theme;

.root {
  background-color: theme.$colorBlue50;
  border-radius: theme.$borderRadiusM;
  padding: theme.size(6 1.5);
  width: 100%;

  @include theme.mediaTabletPortraitUp {
    padding: theme.size(6 4);
  }

  @include theme.mediaDesktopUp {
    padding: theme.size(6 8);
  }
}

.illustration {
  object-fit: contain;
  pointer-events: none;
}
